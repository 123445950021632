import { useEffect, useState } from "react";
import { Search } from "./search";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

const db = getFirestore();
const COLLECTION_TAGS = "tags";
const COLLECTION_VIDEO_TAGS = "videoTags";
const COLLECTION_VIDEO_NOTES = "videoNotes";

export const SearchWrapper = ({ uid, resultsCallback }) => {
  const [items, setItems] = useState([]);

  const fetchDocuments = async () => {
    let fetchedItems = [];

    const tags = [];
    const qTags = query(
      collection(db, COLLECTION_TAGS),
      where("uid", "==", uid)
    );
    const querySnapshotTags = await getDocs(qTags);
    querySnapshotTags.forEach((doc) => {
      const data = doc.data();
      tags.push({
        ...data,
        id: doc.id,
      });
    });

    const qVideoTags = query(
      collection(db, COLLECTION_VIDEO_TAGS),
      where("uid", "==", uid)
    );
    const querySnapshotVideoTags = await getDocs(qVideoTags);
    querySnapshotVideoTags.forEach((doc) => {
      const data = doc.data();
      const matchedTag = tags.find((i) => i.id === data.tagId);

      fetchedItems.push({
        ...data,
        title: matchedTag.value,
        id: doc.id,
        type: "TAG",
      });
    });

    const qVideoNotes = query(
      collection(db, COLLECTION_VIDEO_NOTES),
      where("uid", "==", uid)
    );
    const querySnapshotVideoNotes = await getDocs(qVideoNotes);
    querySnapshotVideoNotes.forEach((doc) => {
      const data = doc.data();

      fetchedItems.push({
        ...data,
        title: data.text,
        id: doc.id,
        type: "NOTE",
      });
    });

    return fetchedItems;
  };

  const callback = ({ type, data }) => {
    switch (type) {
      case "SEARCH_RESULTS":
        resultsCallback({ results: data.results, query: data.query });
        break;
      case "SELECT":
        // Navigatge to the video page for that selected item
        const url = `/video/${uid}/${data.selection.videoId}`;
        window.location.href = url;
        break;
      default:
    }
  };

  useEffect(() => {
    // Fetch items on first load
    const run = async () => {
      const docs = await fetchDocuments();
      setItems(docs);
    };

    run();
  }, []);

  return <Search items={items} callback={callback} />;
};
