import React, { useState } from "react";
import { Box, Center, Text, Flex, Heading } from "@chakra-ui/react";
import { Form, Alert, Button } from "react-bootstrap";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import Layout from "../layout/index";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const [status, setStatus] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);

  const db = getFirestore();

  const loginUser = async (e) => {
    e.preventDefault();
    setIsActive(true);

    try {
      let response = await signInWithEmailAndPassword(
        getAuth(),
        user.email,
        user.password
      );

      //console.log("response:", response, response.user.accessToken)

      if (response._tokenResponse.refreshToken != null) {
        sessionStorage.setItem(
          "Auth Token",
          response._tokenResponse.refreshToken
        );
        sessionStorage.setItem("localId", response._tokenResponse.localId);
        let localId = response._tokenResponse.localId;

        const userRef = await doc(db, "users", localId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          let dd = userSnap.data();
          sessionStorage.setItem("fullName", dd["fullName"]);
        }
        let data = null;

        setIsActive(false);
        navigate("/dashboard");
      } else {
        setStatus(true);
        setIsActive(false);
      }
    } catch (error) {
      console.log("Error:", error);
      setError(true);
    }
  };

  return (
    <Layout noFooter>
      <Flex justify="center" align="center" height="90vh" direction="column">
        <Box p={5} boxShadow="md" bg="white" borderRadius="md" px={9}>
          <Heading as="h2" size="lg" textAlign="center" mb={5}>
            Login
          </Heading>

          <Form onSubmit={loginUser}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={user.email}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={user.password}
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
                placeholder="Password"
              />
            </Form.Group>

            {status || error ? (
              <Alert variant="danger" className="mb-3">
                Invalid email or password.
              </Alert>
            ) : null}

            <Button variant="primary" type="submit" className="mb-3">
              Login
            </Button>
          </Form>

          <Box my={3}>
            <a href="/register">
              New User? <u>Create Account</u>
            </a>
            <Box mt={2} />
            <a href="/account-recovery">
              Forgot Password? <u>Password Reset</u>
            </a>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default Login;
