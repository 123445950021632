import { Flex, Text, Textarea } from "@chakra-ui/react";
import {
  Timestamp,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";

const db = getFirestore();
const COLLECTION = "videoNotes";

export const Notes = ({ videoId, uid }) => {
  const [document, setDocument] = useState(null);
  const storageDocRef = useRef(doc(db, COLLECTION, videoId));

  const handleChange = (e) => {
    // Build note object for saving to db
    const payload = {
      videoId,
      text: e.target.value,
      uid,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    // If creating note for first time, append a createdAt Timestamp
    if (!document?.createdAt) {
      payload.createdAt = Timestamp.fromDate(new Date());
    }

    // Set local document
    // Merge new values into existing document
    setDocument((curr) => ({ ...curr, ...payload }));

    // Set document in db
    setDoc(storageDocRef.current, payload, { merge: true });
  };

  const handleBlur = (e) => {
    // Bail if there's a value existing in notes bcs it's already been saved
    if (e.target.value) return false;

    // Build note object for saving to db
    const payload = {
      videoId,
      text: e.target.value,
      uid,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    // If creating note for first time, append a createdAt Timestamp
    if (!document?.createdAt) {
      payload.createdAt = Timestamp.fromDate(new Date());
    }

    // Set local document
    // Merge new values into existing document
    setDocument((curr) => ({ ...curr, ...payload }));

    // Set document in db
    setDoc(storageDocRef.current, payload, { merge: true });
  };

  // Retrieve & set note if it exists
  useEffect(() => {
    const run = async () => {
      const docSnap = await getDoc(storageDocRef.current);

      if (docSnap.exists()) {
        const { text, createdAt } = docSnap.data();
        setDocument({ text, createdAt });
      }
    };

    if (storageDocRef.current) run();
  }, [storageDocRef]);

  return (
    <Flex direction="column">
      <Text>Notes</Text>
      <Textarea
        background="white"
        placeholder="Enter video notes"
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={document?.text || ""}
      ></Textarea>
    </Flex>
  );
};
