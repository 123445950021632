import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Box,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  Tooltip,
  SliderThumb,
  Spacer,
  Text,
  Flex,
  Heading,
  CircularProgress,
  SkeletonText,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Grid,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "../dashboard.scss";
import { useNavigate } from "react-router-dom";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { FiBarChart, FiUpload, FiSettings, FiPower } from "react-icons/fi";
import { key, findLabelIndex, formatTime } from "../lib/index";
import { metadata, metadataCategories } from "../lib/videoMetadata";
import { Tags } from "./tags";
import { Notes } from "./notes";
import { TagsWrapper } from "./tags-wrapper";

const db = getFirestore();
const animatedComponents = makeAnimated();

const VideoMetadataForm = ({ videoID }) => {
  const initialFormValues = metadata.reduce((values, item) => {
    values[item.key] = item.type === "multi select" ? [] : "";
    return values;
  }, {});

  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [isInitialValuesLoaded, setIsInitialValuesLoaded] = useState(false);
  const saveConfirmationToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "metadata", videoID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setInitialValues((prevState) => ({ ...prevState, ...docSnap.data() }));
      }
      setIsInitialValuesLoaded(true);
    };

    fetchData();
  }, [videoID]);

  const handleSubmit = async (values, actions) => {
    const docRef = doc(db, "metadata", videoID);

    try {
      await setDoc(docRef, values);
      actions.setSubmitting(false);

      saveConfirmationToast({
        title: "Video metadata saved",
        description: "Your metadata has been saved.",
        status: "success",
        duration: 6000,
        isClosable: true,
      });
    } catch (error) {
      actions.setSubmitting(false);

      saveConfirmationToast({
        title: "Error",
        description: "Failed to save metadata. Please try again.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  if (!isInitialValuesLoaded) {
    return <Spinner />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          {metadataCategories.map((category) => (
            <Box key={category.key} mb={3}>
              <Box fontWeight="bold" mb={3} borderBottom="1px solid #eee">
                <Heading size="sm">{category.name}</Heading>
              </Box>
              <Stack>
                {metadata
                  .filter((item) => item.category === category.name)
                  .map((field) => {
                    let InputComponent;
                    let selectOptions;
                    let checkboxValue;

                    switch (field.type) {
                      case "checkbox":
                        InputComponent = Checkbox;
                        checkboxValue = values[field.key] ? "checked" : "";
                        break;
                      case "single select":
                      case "multi select":
                        InputComponent = Select;
                        selectOptions = field.options.map((option) => ({
                          value: option,
                          label: option,
                        }));
                        break;
                      case "number":
                      case "text":
                      default:
                        InputComponent = Input;
                        break;
                    }

                    return (
                      <Field
                        key={field.key}
                        name={field.key}
                        validate={(value) => {
                          if (
                            value &&
                            field.validation &&
                            !new RegExp(field.validation).test(value)
                          ) {
                            return `Invalid input for ${field.label}`;
                          }
                        }}
                      >
                        {(props) => (
                          <FormControl
                            isInvalid={props.meta.error && props.meta.touched}
                          >
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                              <Box py={1}>
                                <Text>{field.label}</Text>
                              </Box>
                              {
                                // Text input fields
                                InputComponent === Input && (
                                  <InputComponent
                                    id={props.field.name}
                                    name={props.field.name}
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    placeholder={field.placeholder}
                                    flex="1"
                                  />
                                )
                              }
                              {
                                // Checkbox input fields
                                InputComponent === Checkbox && (
                                  <InputComponent
                                    id={props.field.name}
                                    name={props.field.name}
                                    isChecked={checkboxValue}
                                    onChange={(e) => {
                                      setFieldValue(
                                        field.key,
                                        e.target.checked
                                      );
                                    }}
                                    flex="1"
                                  />
                                )
                              }

                              {
                                // Single and multi select input fields
                                InputComponent === Select && (
                                  <InputComponent
                                    id={props.field.name}
                                    name={props.field.name}
                                    isMulti={field.type === "multi select"}
                                    options={selectOptions}
                                    components={animatedComponents}
                                    isClearable // This prop lets you clear the selection
                                    value={
                                      field.type === "multi select"
                                        ? values[field.key].map((val) => ({
                                            value: val,
                                            label: val,
                                          }))
                                        : values[field.key]
                                        ? {
                                            value: values[field.key],
                                            label: values[field.key],
                                          }
                                        : null // handling null or empty value
                                    }
                                    onChange={(e) => {
                                      if (field.type === "multi select") {
                                        setFieldValue(
                                          field.key,
                                          e ? e.map((x) => x.value) : []
                                        );
                                      } else if (
                                        field.type === "single select"
                                      ) {
                                        setFieldValue(
                                          field.key,
                                          e ? e.value : ""
                                        );
                                      }
                                    }}
                                    flex="1"
                                  />
                                )
                              }
                            </Grid>
                            {props.meta.error && props.meta.touched && (
                              <Box color="red.500" fontSize="sm" mt={1}>
                                {props.meta.error}
                              </Box>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    );
                  })}
              </Stack>
            </Box>
          ))}
          <Flex>
            <Button
              colorScheme="teal"
              bg="pt-blue"
              isLoading={isSubmitting}
              type="submit"
            >
              Save Metadata
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

const VideoPlayer = ({ url, videoID, demo = false, uid }) => {
  if (!videoID) {
    console.error("No videoID provided to video player!");
  }

  const [videoDetails, setVideoDetails] = useState();
  const [videoInfo, setVideoInfo] = useState();
  const [insights, setInsights] = useState();
  const [videoProcessed, setVideoProcessed] = useState(false); // Assume the video hasn't been processed yet till we fetch insights

  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.removeItem("Auth Token");
    navigate("/");
  };

  const fetchVideoInfo = async () => {
    const ref = collection(db, "videos");
    const q = query(ref, where("videoId", "==", videoID));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size == 0) {
      console.error(`No video with videoID ${videoID} found`);
    } else if (querySnapshot.size > 1) {
      console.error(`Several videos with videoID ${videoID} found`);
    }

    querySnapshot.forEach((doc) => {
      // videoInfo is just the entire video object
      console.log(doc.data());
      setVideoInfo(doc.data());
    });
  };

  const fetchDetails = async () => {
    const ref = collection(db, "modelOutput");

    //console.log(videoID);

    const q = query(ref, where("videoId", "==", videoID));

    const querySnapshot = await getDocs(q);

    // Insights for the video exists
    // TODO: Check if more that one set of insights exists? This should never be the case, same issue as below
    if (querySnapshot.size == 1) {
      //console.log("Insights fetched for video");
      //setVideoProcessed(true);
    }

    // console.log(querySnapshot.docs);

    // TODO: Known issue that there may be several modelOutputs for the same video.
    // This issue should be addressed instead of just overwriting
    // This should only occur for the one video we are fetching. There should only be one document returned.
    // compare timestamps for sorting
    const compareTimestamps = (a, b) => {
      return a.timestamp - b.timestamp;
    };
    querySnapshot.docs
      .sort((a, b) => compareTimestamps(a.data().timestamp, b.data().timestamp))
      .forEach((doc) => {
        // Converts the modelOutput json object from start_range/end_range format to start_time/end_time if needed
        const convertMixedModelOutputs = (data) => {
          let convertedData = [];

          data.forEach((entry) => {
            if ("start_time" in entry && "end_time" in entry) {
              // If start_time and end_time are present, keep the entry as is
              convertedData.push(entry);
            } else if ("start_range" in entry && "end_range" in entry) {
              // If start_range and end_range are present, uncoalesce into individual intervals
              const label = entry.label;
              let currentTime = entry.start_range;

              while (currentTime < entry.end_range) {
                convertedData.push({
                  start_time: currentTime,
                  end_time: currentTime + 1000, // Assuming each interval is 1000ms
                  label: label,
                });
                currentTime += 1000; // Move to the next interval
              }
            }
          });

          return convertedData;
        };

        const vidDetailTimestamps = convertMixedModelOutputs(
          JSON.parse(doc.data().processedOutput.replaceAll("'", '"')).results
        );
        // console.log(vidDetailTimestamps);

        setVideoDetails(vidDetailTimestamps);
        setInsights(JSON.parse(doc.data().insights.replaceAll("'", '"')));

        // console.log("insights")

        setVideoProcessed(true);
      });
  };

  useEffect(() => {
    fetchVideoInfo();
    fetchDetails();
  }, []);

  const [playing, setPlaying] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [player, setPlayer] = useState();
  const [seeking, setSeeking] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState();
  const [currStep, setCurrStep] = useState();

  const ref = (player) => {
    setPlayer(player);
  };

  const getCurrStep = (time) => {
    for (let i = 0; i < videoDetails.length; i++) {
      if (time <= videoDetails[i].start_time / 1000) {
        setCurrStep(videoDetails[i].label);
        break;
      }
    }
    //return "";
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (v) => {
    setSeeking(false);
    player.seekTo(v, "seconds");
  };

  const handleProgress = (state) => {
    if (videoProcessed) {
      getCurrStep(state.playedSeconds);
    }
    if (!seeking) setPlayedSeconds(state.playedSeconds);
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    if (!isNaN(date.getTime())) {
      // Check if date is valid
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-indexed
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else {
      return "Invalid date"; // Fallback message for invalid dates
    }
  }

  return (
    <Box>
      <Flex>
        <Box m={6} ml={9}>
          <Flex
            py={3}
            my={6}
            alignItems="center"
            justifyContent="space-between"
          >
            <Spacer />
            <Box>
              <Box mb={3}>
                <Heading size="lg">
                  {!demo && videoInfo && videoInfo.filename}
                </Heading>
                <Heading size="md">
                  {!demo &&
                    videoInfo &&
                    formatDate(videoInfo.timestamp.toDate())}
                </Heading>
              </Box>
              <ReactPlayer
                controls
                playing={playing}
                onReady={() => {
                  setTimeout(() => {
                    setPlayerReady(true);
                  }, 2000); // onReady is called before player is actually ready? Bad practice, but waiting at least 1 second seems to do the trick
                }}
                ref={ref}
                url={url}
                onProgress={(state) => handleProgress(state)}
              />
              {videoProcessed && (
                <Flex flexDirection="row-reverse" py={3}>
                  Currently performing: {currStep}
                </Flex>
              )}
            </Box>
            <Spacer />
            <Box>
              {insights &&
                key.map((i) => {
                  // Only render steps within the key that exist within the insights. Steps that don't appear in the video won't show up in the key
                  if (insights[i.label] && insights[i.label].count > 0) {
                    return (
                      <Flex m={3} key={i.label}>
                        <Box bg={i.color} height={30} width={30} />
                        <Text mx={2}>{i.label}</Text>
                      </Flex>
                    );
                  }
                })}
            </Box>

            <Spacer />
          </Flex>
          {playerReady ? (
            <Box>
              <Slider
                size="sm"
                focusThumbOnChange={false} // don't focus the slider when its value changes
                defaultValue={0}
                min={0}
                max={player.getDuration()}
                onChangeStart={(v) => handleSeekMouseDown(v)}
                onChangeEnd={(v) => handleSeekMouseUp(v)}
                onChange={(v) => setPlayedSeconds(v)}
                value={playedSeconds}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <SliderTrack height={5}>
                  <SliderFilledTrack bg="gainsboro" />
                </SliderTrack>

                {videoProcessed &&
                  videoDetails.map((timestamp, i) => {
                    // console.log("Rendering video detail");
                    // console.log(timestamp);
                    if (timestamp.label !== "no label") {
                      if ("start_time" in timestamp) {
                        const start_time = timestamp.start_time / 1000;
                        // console.log(timestamp.label)
                        return (
                          <SliderMark
                            key={timestamp.start_time}
                            value={start_time}
                            mt="-18"
                          >
                            <Box
                              borderRadius={10}
                              height={9}
                              width={1}
                              bg={key[findLabelIndex(timestamp.label)].color}
                            />
                          </SliderMark>
                        );
                      } else {
                        console.error(
                          "No start_time found for timestamp",
                          timestamp
                        );
                      }
                    }
                  })}

                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={
                    // TODO: Manipulate this with a state object, the current label will not update unless it is rerendered (user has to hover over the tooltip in order to see the most current state)
                    // player.getCurrentTime() is only updated after scrubbing is done, so this will have to be done with onProgress
                    playerReady
                      ? currStep + " | " + formatTime(player.getCurrentTime())
                      : "Player not ready"
                  }
                >
                  <SliderThumb size="sm">
                    <Box
                      width={1}
                      height={16}
                      bg="whitesmoke"
                      borderWidth={1}
                    />
                  </SliderThumb>
                </Tooltip>
              </Slider>

              <Box my={16}>
                <Heading size="sm" m={2}>
                  <Flex>
                    Summary | Total time:{" "}
                    {playerReady ? (
                      formatTime(player.getDuration())
                    ) : (
                      <CircularProgress isIndeterminate size={5} mx={3} />
                    )}
                  </Flex>
                </Heading>
                <Box borderRadius={6} shadow="md" bg="white" p={3}>
                  {!videoProcessed && (
                    <Text>
                      This video has not been processed yet. Come back in ~24
                      hours to see the insights.
                    </Text>
                  )}
                  <Flex
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    {insights &&
                      key.map((i) => {
                        let time = 0;

                        if (insights[i.label]) {
                          time = insights[i.label].timeTaken;
                        }

                        return (
                          <Text key={i.label} m={1}>
                            {i.label} | {formatTime(time / 1000)}
                          </Text>
                        );
                      })}
                  </Flex>
                </Box>
                {!demo && (
                  <Box my={16}>
                    <Heading size="sm" m={2}>
                      Metadata
                    </Heading>
                    <Box borderRadius={6} shadow="md" bg="white" p={3}>
                      <VideoMetadataForm videoID={videoID} />
                    </Box>
                  </Box>
                )}

                <Box>
                  <TagsWrapper videoId={videoID} uid={uid} />
                </Box>

                <Box padding="20px 0 0">
                  <Notes videoId={videoID} uid={uid} />
                </Box>
              </Box>
            </Box>
          ) : (
            <SkeletonText mt={4} noOfLines={6} spacing="3" height={10} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default VideoPlayer;
