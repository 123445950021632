import { useEffect, useState } from "react";
import { Tags } from "./tags";
import { Flex, Text } from "@chakra-ui/react";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";

const db = getFirestore();
const COLLECTION_TAGS = "tags";
const COLLECTION_VIDEO_TAGS = "videoTags";

export const TagsWrapper = ({ videoId, uid }) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const fetchTags = async () => {
    // Run async db fetch call
    const q = query(collection(db, COLLECTION_TAGS), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });

    return items;
  };

  const fetchVideoTags = async ({ tags }) => {
    // Run async db fetch call
    const querySnapshot = await getDocs(
      query(
        collection(db, COLLECTION_VIDEO_TAGS),
        where("videoId", "==", videoId)
      )
    );
    const items = [];
    querySnapshot.forEach((doc) => {
      const data = { ...doc.data(), id: doc.id };
      const foundTag = tags.find((i) => i.id === data.tagId);

      if (foundTag) items.push({ ...data, value: foundTag.value });
    });

    return items;
  };

  const handleAdd = async ({ tag }) => {
    // Save tag to db
    const storageDocRef = doc(db, COLLECTION_TAGS, tag.id);
    const payload = {
      videoId,
      uid,
      value: tag.value,
      updatedAt: Timestamp.fromDate(new Date()),
    };
    await setDoc(storageDocRef, payload, { merge: true });

    // Save tag-video pairing
    const videoTagRef = doc(collection(db, COLLECTION_VIDEO_TAGS));
    const payloadVideoTag = {
      tagId: tag.id,
      uid,
      videoId,
      updatedAt: Timestamp.fromDate(new Date()),
    };
    await setDoc(videoTagRef, payloadVideoTag, { merge: true });

    return true;
  };

  const handleRemove = async ({ tag, selectedTags }) => {
    // Query for existing video tag
    const querySnapshot = await getDocs(
      query(
        collection(db, COLLECTION_VIDEO_TAGS),
        where("videoId", "==", videoId),
        where("tagId", "==", tag.tagId)
      )
    );

    // Assemble video tag query result
    const items = [];
    querySnapshot.forEach((doc) => {
      const data = { ...doc.data(), id: doc.id };
      items.push(data);
    });

    const videoTag = items?.length ? items[0] : null;

    // Remove this video tag
    if (videoTag) {
      const videoTagRef = doc(db, COLLECTION_VIDEO_TAGS, videoTag.id);
      await deleteDoc(videoTagRef);
    }

    return true;
  };

  const callback = async ({ type, data }) => {
    switch (type) {
      case "ADD":
        await handleAdd({ tag: data.tag });
        break;
      case "REMOVE":
        await handleRemove({ tag: data.tag, selectedTags: data.selectedTags });
        break;
      default:
    }
  };

  useEffect(() => {
    // Get initial tags from db
    // And set local state based on them
    const run = async () => {
      const tags = await fetchTags();
      const videoTags = await fetchVideoTags({ tags });
      setItems(tags);
      setSelectedItems(videoTags);
    };

    run();
  }, []);

  return (
    <Flex direction="column">
      <Text>Tags</Text>
      <Tags items={items} selectedItems={selectedItems} callback={callback} />
    </Flex>
  );
};
