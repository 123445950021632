import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import Layout from "../layout/index";

const AccountRecovery = () => {
  const [user, setUser] = useState({
    email: "",
  });

  const navigate = useNavigate();

  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const db = getFirestore();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };


  const handleForgotPassword = async (event) => {
    event.preventDefault(); // this line prevents the page from refreshing
    if (validateEmail(user.email)) {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, user.email)
        .then(() => {
          setResetEmailSent(true);
          setError(false);
        })
        .catch((error) => {
          console.log("Password reset error: ", error);
          setError(true);
          setResetEmailSent(false);
        });
    } else {
      setError(true);
      setResetEmailSent(false);
    }
  };

  return (
    <div className="login-wrapper">
      <Layout>
        <Row className="login-form">
          <Col sm={12} md={6} className={"align-self-center"}>
            {status ||
              (error && (
                <Alert variant="danger">Invalid email or password.</Alert>
              ))}
            <div className="login-form-wrapper">
              <Form onSubmit={handleForgotPassword}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={user.email}
                    onChange={(e) => {
                      setUser({ ...user, email: e.target.value });
                    }}
                    placeholder="Enter email"
                  />
                </Form.Group>

                <Button
                  onClick={handleForgotPassword}
                  variant="primary"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Form>

              <Box my={3}>
                <a href="/login">
                  <u>Return to login</u>
                </a>
                {resetEmailSent && (
                  <Alert variant="success">
                    Reset password email has been sent.
                  </Alert>
                )}
              </Box>
            </div>
          </Col>
          <Col md={6}>
            <img id="auth" src="/bg.png" alt="image" />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default AccountRecovery;
