// All the metadata fields for videos

// Definitions of all the possible metadata form categories for form groups and map categories in firestore
export const metadataCategories = [
  {
    name: "General", // The name of the metadata category that will be rendered as a form group
    key: "general", // The key that the category will be saved as in firestore
  },
  {
    name: "Visual Acuity",
    key: "visualAcuity",
  },
  {
    name: "Refraction",
    key: "refraction",
  },
  {
    name: "Biometry",
    key: "biometry",
  },
  {
    name: "Lens exam",
    key: "lensExam",
  },
  {
    name: "Cataract Rating",
    key: "cataractRating",
  },
  {
    name: "Surgical Technique and Complications",
    key: "techniqueAndComplications",
  },
];

/*
Fields:
category: string // References the category name that the input should fall under 
label: string // The question text that will show up on the form
validation: regex string // Regex that will validate the field input
type: string // Type of question, such as single select, multi select, text, etc.
placeholder: string // The placeholder for the field if relevant
options: array of strings // The possible options for the field if relevant
key: string // The key that will reference the item in firestore
*/
export const metadata = [
  {
    category: "General",
    label: "Case Number",
    validation: null,
    type: "number",
    placeholder: null,
    options: null,
    key: "caseNumber",
  },
  {
    category: "Visual Acuity",
    label: "Pre-op",
    validation: null,
    type: "single select",
    placeholder: null,
    options: [
      "LP",
      "HM",
      "CF",
      "20/250",
      "20/200",
      "20/100",
      "20/80",
      "20/70",
      "20/60",
      "20/50",
      "20/40",
      "20/30",
      "20/25",
      "20/20",
      "20/15",
    ],
    key: "preOp",
  },
  {
    category: "Visual Acuity",
    label: "Postoperative day 1",
    validation: null,
    type: "single select",
    placeholder: null,
    options: [
      "LP",
      "HM",
      "CF",
      "20/250",
      "20/200",
      "20/100",
      "20/80",
      "20/70",
      "20/60",
      "20/50",
      "20/40",
      "20/30",
      "20/25",
      "20/20",
      "20/15",
    ],
    key: "postoperativeDay1",
  },
  {
    category: "Visual Acuity",
    label: "Postoperative week 1",
    validation: null,
    type: "single select",
    placeholder: null,
    options: [
      "LP",
      "HM",
      "CF",
      "20/250",
      "20/200",
      "20/100",
      "20/80",
      "20/70",
      "20/60",
      "20/50",
      "20/40",
      "20/30",
      "20/25",
      "20/20",
      "20/15",
    ],
    key: "postoperativeWeek1",
  },
  {
    category: "Visual Acuity",
    label: "Final",
    validation: null,
    type: "single select",
    placeholder: null,
    options: [
      "LP",
      "HM",
      "CF",
      "20/250",
      "20/200",
      "20/100",
      "20/80",
      "20/70",
      "20/60",
      "20/50",
      "20/40",
      "20/30",
      "20/25",
      "20/20",
      "20/15",
    ],
    key: "final",
  },
  {
    category: "Refraction",
    label: "Preoperative",
    validation:
      "^[-+]?[0-9]*.?[0-9]+\\s[-+]?[0-9]*.?[0-9]+\\s@\\s[1-9][0-9]?$|^180$",
    type: "text",
    placeholder: "(+/-) sphere (+/-) cyl @ axis",
    options: null,
    key: "preoperative",
  },
  {
    category: "Refraction",
    label: "Postoperative",
    validation:
      "^[-+]?[0-9]*.?[0-9]+\\s[-+]?[0-9]*.?[0-9]+\\s@\\s[1-9][0-9]?$|^180$",
    type: "text",
    placeholder: "(+/-) sphere (+/-) cyl @ axis",
    options: null,
    key: "postoperative",
  },
  {
    category: "Biometry",
    label: "Axial length",
    validation: "",
    type: "number",
    placeholder: "",
    options: null,
    key: "axialLength",
  },
  {
    category: "Biometry",
    label: "K1/K2 @ axis",
    validation: "^[0-9]*.?[0-9]+/[0-9]*.?[0-9]+/@[1-9][0-9]?$|^180$",
    type: "text",
    placeholder: "(number)/(number)/(number)",
    options: null,
    key: "k1k2axis",
  },
  {
    category: "Biometry",
    label: "Intraocular lens model chosen",
    validation: null,
    type: "text",
    placeholder: null,
    options: null,
    key: "intraocularLensModel",
  },
  {
    category: "Biometry",
    label: "Toric lens?",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "toricLens",
  },
  {
    category: "Biometry",
    label: "Multifocal lens?",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "multifocalLens",
  },
  {
    category: "Biometry",
    label: "Anticipated residual refractive error",
    validation: "^-?[0-9]*.?[0-9]+$",
    type: "text",
    placeholder: null,
    options: null,
    key: "anticipatedResidualRefractiveError",
  },
  {
    // TODO: check if this is proper?
    category: "Biometry",
    label: "Anticipated residual refractive error formula",
    validation: null,
    type: "text",
    placeholder: null,
    options: null,
    key: "formula",
  },
  {
    category: "Lens exam",
    label: "Pseudoexfoliation",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "pseudoexfoliation",
  },
  {
    category: "Lens exam",
    label: "Flomax use",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "flomaxUse",
  },
  {
    category: "Lens exam",
    label: "Other notes",
    validation: null,
    type: "text",
    placeholder: null,
    options: null,
    key: "other",
  },
  {
    category: "Cataract Rating",
    label: "Nuclear sclerosis",
    validation: null,
    type: "single select",
    placeholder: null,
    options: ["0", "1+", "2+", "3+", "4+"],
    key: "nuclearSclerosis",
  },
  {
    category: "Cataract Rating",
    label: "Cortical cataract",
    validation: null,
    type: "single select",
    placeholder: null,
    options: ["0", "1+", "2+", "3+", "4+"],
    key: "corticalCataract",
  },
  {
    category: "Cataract Rating",
    label: "Posterior subcapsular cataract",
    validation: null,
    type: "single select",
    placeholder: null,
    options: ["0", "1+", "2+", "3+", "4+"],
    key: "posteriorSubcapsularCataract",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Surgical Technique",
    validation: null,
    type: "multi select",
    placeholder: null,
    options: ["Divide and Conquer", "Stop and Chop", "Chop"],
    key: "surgicalTechnique",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Additional Techniques",
    validation: null,
    type: "multi select",
    placeholder: null,
    options: ["Horizontal chop", "Vertical chop"],
    key: "additionalTechniques",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Iris Expansion",
    validation: null,
    type: "single select",
    placeholder: null,
    options: [
      "Malyugin ring 6.25",
      "Malyugin ring 7.0",
      "Iris hooks (4)",
      "Iris hooks (5)",
    ],
    key: "irisExpansion",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Second Instrument",
    validation: null,
    type: "text",
    placeholder: null,
    options: null,
    key: "secondInstrument",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Femtosecond laser",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "femtosecondLaser",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Intraoperative Aberrometry",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "intraoperativeAberrometry",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Limbal relaxing incision",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "limbalRelaxingIncision",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Capsular tension ring",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "capsularTensionRing",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Anterior vitrectomy",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "anteriorVitrectomy",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Anterior capsular tear",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "anteriorCapsularTear",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Posterior capsular tear",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "posteriorCapsularTear",
  },
  {
    category: "Surgical Technique and Complications",
    label: "Sutured wound",
    validation: null,
    type: "checkbox",
    placeholder: null,
    options: null,
    key: "suturedWound",
  },
];
