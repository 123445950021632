import React, { useState, useEffect } from "react";
import {
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Box,
  Text,
} from "@chakra-ui/react";

import { FiBarChart, FiUpload, FiSettings, FiPower } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/index";
import "../../dashboard.scss";
import { Button } from "react-bootstrap";

import Overview from "../tabs/overview";
import Upload from "../tabs/upload";
import Settings from "../tabs/settings";
import UserDropdowns from "../tabs/userdropdowns";

import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

const Dashboard = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
        // Could also get the uid from user.uid and display on the profile
        //sessionStorage.setItem("localId", user.uid);
      } else {
        setEmail(null);
      }
    });
    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  const logout = () => {
    sessionStorage.removeItem("Auth Token");
    navigate("/");
  };

  return (
    <Layout>
      <Box py={3}>
          <Upload />
      </Box>
    </Layout>
  );
};

export default Dashboard;
