import { useState, useEffect, useRef } from "react";
import MiniSearch from "minisearch";

const Search = ({ items, callback, mode = "DEFAULT" }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const resultsRef = useRef(null);

  const handleSelection = ({ selection }) => {
    callback({
      type: "SELECT",
      data: { selection: selection },
    });
  };

  useEffect(() => {
    const miniSearch = new MiniSearch({
      fields: ["title"], // fields to index for full-text search
      storeFields: ["title", "type", "videoId"], // fields to return with search results
    });

    miniSearch.addAll(items);

    if (query) {
      const results = miniSearch.search(query, { prefix: true });
      setSearchResults(results);
      setSelectedItemIndex(-1); // Reset selected item index when query changes
      callback({ type: "SEARCH_RESULTS", data: { results, query } });
    } else {
      setSearchResults([]);
      setSelectedItemIndex(-1); // Reset selected item index when query is empty
      callback({ type: "SEARCH_RESULTS", data: { results: [], query } });
    }

    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setSearchResults([]); // Clear search results when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [query]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedItemIndex((prevIndex) =>
        Math.min(prevIndex + 1, searchResults.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedItemIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter" && selectedItemIndex !== -1) {
      e.preventDefault();
      handleSelection({ selection: searchResults[selectedItemIndex] });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        width: "100%",
        minWidth: "300px",
        maxWidth: "500px",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          width: "100%",
          border: "1px solid #ccc",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search..."
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            padding: "5px",
            fontSize: "14px",
          }}
        />
        {searchResults.length > 0 && mode === "INLINE" && (
          <div
            ref={resultsRef}
            style={{
              position: "absolute",
              top: "calc(100% + 10px)",
              left: 0,
              width: "100%",
              background: "#fff",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              zIndex: 999,
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #333",
              maxHeight: "200px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {searchResults.map((result, index) => (
              <div
                key={result.id}
                style={{
                  padding: "5px 10px",
                  textAlign: "left",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor:
                    selectedItemIndex === index ? "#f0f0f0" : "transparent",
                }}
                onMouseEnter={() => setSelectedItemIndex(index)}
                onMouseLeave={() => setSelectedItemIndex(-1)}
                onClick={() => {
                  handleSelection({ selection: result });
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {result.title}
                </div>
                <div style={{ color: "#888", fontSize: "12px" }}>
                  {result.type}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { Search };
