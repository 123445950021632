import React, { useState, useEffect } from "react";
import {
  Heading,
  Flex,
  Box,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Icon
} from "@chakra-ui/react";
import { FiBarChart, FiUpload, FiSettings, FiPower } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/index";
import Overview from "../tabs/overview";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

const Dashboard = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      } else {
        setEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const logout = () => {
    sessionStorage.removeItem("Auth Token");
    navigate("/");
  };

  return (
    <Layout>
     

      <Box p={4}>
        <Overview />
      </Box>
    </Layout>
  );
};

export default Dashboard;
