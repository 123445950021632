import { Input } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

const Tags = ({ items, selectedItems, callback }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    if (items.length) setSuggestions(items);
  }, [items]);

  useEffect(() => {
    if (selectedItems.length) setSelectedTags(selectedItems);
  }, [selectedItems]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setHighlightedIndex(-1); // Reset the highlighted index when input changes
  };

  const handleSelect = (tag) => {
    setSelectedTags([...selectedTags, tag]);

    if (!suggestions.find((i) => i.id === tag.id)) {
      setSuggestions((curr) => [...curr, tag]);
    }

    callback({ type: "ADD", data: { tag } });

    setInputValue("");
    setHighlightedIndex(-1); // Reset the highlighted index after selection
  };

  const handleRemoveTag = (tag) => {
    const adjustedSelectedTags = selectedTags.filter((i) => i.id !== tag.id);
    setSelectedTags(adjustedSelectedTags);
    callback({
      type: "REMOVE",
      data: { tag, selectedTags: adjustedSelectedTags },
    });
  };

  const handleKeyDown = (e) => {
    const filteredSuggestions = getFilteredSuggestions();
    if (e.key === "Enter") {
      if (
        highlightedIndex >= 0 &&
        highlightedIndex < filteredSuggestions.length
      ) {
        handleSelect(filteredSuggestions[highlightedIndex]);
      } else if (
        !suggestions.find((i) => i.value === inputValue.trim()) &&
        !selectedTags.find((i) => i.value === inputValue.trim())
      ) {
        handleSelect({
          id: nanoid(),
          value: inputValue.trim(),
        });
      }
    } else if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1
      );
    }
  };

  const getFilteredSuggestions = () => {
    return suggestions.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedTags.find((i) => i.id === suggestion.id)
    );
  };

  const filteredSuggestions = getFilteredSuggestions();

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "500px",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "5px",
            background: "#fff",
          }}
        >
          {selectedTags.map((tag) => (
            <div
              key={tag}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "4px",
                background: "#e0e0e0",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            >
              {tag.value}

              <div style={{ padding: "0 0 0 8px" }}>
                <div
                  onClick={() => handleRemoveTag(tag)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "1000px",
                    width: "20px",
                    height: "20px",
                    flexShrink: 0,
                    flexGrow: 0,
                    cursor: "pointer",
                    background: "#f8f8f8",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  x
                </div>
              </div>
            </div>
          ))}
          <Input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onKeyDown={handleKeyDown}
            style={{
              border: "none",
              outline: "none",
              flex: "1 0 auto",
              fontSize: "14px",
              padding: "5px",
            }}
            placeholder="Type to add a name"
          />
        </div>
        {isInputFocused && filteredSuggestions.length > 0 && (
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "5px 0",
              border: "1px solid #ccc",
              borderRadius: "5px",
              position: "absolute",
              width: "100%",
              height: "fit-content",
              maxHeight: "500px",
              overflow: "auto",
              top: "100%",
              left: "0",
              backgroundColor: "white",
              zIndex: "1",
            }}
          >
            {filteredSuggestions.map((suggestion, index) => (
              <li
                key={suggestion}
                onMouseDown={() => handleSelect(suggestion)} // Use onMouseDown to prevent input blur before click
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor:
                    highlightedIndex === index ? "#ddd" : "white",
                }}
                onMouseEnter={() => setHighlightedIndex(index)}
                onMouseLeave={() => setHighlightedIndex(-1)}
              >
                {suggestion.value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export { Tags };
