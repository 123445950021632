import {
  Button,
  ButtonGroup,
  Container,
  Center,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import * as React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    setAuthToken(sessionStorage.getItem("Auth Token"));
  }, []);
  return (
    <Container
      as="footer"
      className={authToken ? "hidden" : null}
      role="contentinfo"
    >
      <Stack
        spacing="8"
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        py={{ base: "12", md: "16" }}
      >
        <Stack align="start">
          <Heading color="pt-blue">PhacoTrainer</Heading>
          <Text>Automated cataract surgery video analysis</Text>
        </Stack>
        <Stack
          direction={{ base: "column-reverse", md: "column", lg: "row" }}
          spacing={{ base: "12", md: "8" }}
        >
          {/*
                    <Stack direction="row" spacing="8">
                        <Stack spacing="4" minW="36" flex="1">
                            <Text fontSize="lg" fontWeight="semibold" color="subtle">
                                Product
                            </Text>
                            <Stack spacing="3" shouldWrapChildren>
                                <Button variant="link" href="/#FAQs">FAQs</Button>

                            </Stack>
                        </Stack>
                        <Stack spacing="4" minW="36" flex="1">
                            <Text fontSize="lg" fontWeight="semibold" color="subtle">
                                Legal
                            </Text>
                            <Stack spacing="3" shouldWrapChildren>
                                <Button variant="link">Privacy Policy</Button>
                                <Button variant="link">Terms of Service</Button>
                            </Stack>
                        </Stack>
                        
    
                    </Stack>
*/}
        </Stack>
      </Stack>
      <Center>
        {/*<Stack pb={3} spacing="4">
            <Text fontSize="sm" fontWeight="semibold" textAlign="center">
              Stay up to date
            </Text>
            <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} maxW={{ lg: '360px' }}>
              <Input placeholder="Enter your email" type="email" required />
              <Button variant="primary" type="submit" flexShrink={0}>
                Subscribe
              </Button>
            </Stack>
</Stack>*/}
      </Center>
      {/*<Divider />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} Someone. All rights reserved.
        </Text>
        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
          <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
</ButtonGroup>
      </Stack>*/}
    </Container>
  );
};

export default Footer;
