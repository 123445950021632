import React, { useState } from "react";
import {
  Box,
  Spacer,
  Text,
  Flex,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/react";
import Layout from "../layout/index";
import VideoPlayer from "../videoPlayer";
import {
  MdOutlineAnalytics,
  MdYoutubeSearchedFor,
  MdOutlineAutoGraph,
} from "react-icons/md";

const Landing = () => {
  const points = [
    {
      icon: <MdOutlineAnalytics size={70} />,
      heading: "Review your past surgeries",
      desc: "View analyzed recordings of all your prior surgeries",
    },
    {
      icon: <MdYoutubeSearchedFor size={70} />,
      heading: "Analyze your surgical performance",
      desc: "Surgery performance is auto-analyzed so you can see where to improve",
    },
    {
      icon: <MdOutlineAutoGraph size={70} />,
      heading: "Track your improvement over time",
      desc: "See how your surgical performance improves over time",
    },
  ];

  const steps = [
    {
      heading: "Upload your surgical videos",
      image: (
        <img src="/images/undraw_going_up_re_86kg.svg" alt="Upload video" />
      ),
    },
    {
      heading: "Let PhacoTrainer process your videos",
      image: (
        <img
          src="/images/undraw_fast_loading_re_8oi3.svg"
          alt="Process video"
        />
      ),
    },
    {
      heading: "Analyze your surgeries",
      image: (
        <img src="/images/undraw_screen_time_vkev.svg" alt="Analyze video" />
      ),
    },
  ];

  const FAQs = [
    {
      q: "What is PhacoTrainer used for",
      a: "Phacotrainer is a surgery tool for residents to measure and improve their performance over time. Residents can easily use the model to track surgical times on a granular level and determine which steps they might need further improvement. Furthermore, detecting surgery utensils in real-time has applications in AI-assisted surgery such as notifying a surgeon when forceps veer from the center of the pupil.",
    },
    {
      q: "How does PhacoTrainer work?",
      a: "The phacotrainer algorithm uses video-based ML to automatically detect which step in a surgery is represented by each frame as well as segment them by where surgery utensils appear.",
    },
  ];

  return (
    <Layout>
      <Flex>
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Heading>Improve your cataract surgical skills</Heading>
            <Text fontSize="lg" my={3}>
              Upload your cataract surgical videos to track your surgical times
              and improve your performance
            </Text>
            <Flex my={3}>
              <Link href="/#demo">
                <Button
                  mr={6}
                  color="white"
                  bg="pt-blue"
                  borderRadius={100}
                  _hover={{ bg: "pt-navy" }}
                >
                  See how
                </Button>
              </Link>
              <Link href="/login">
                {/*<Button
                color="white"
                bg="pt-blue"
                borderRadius={100}
                _hover={{ bg: "pt-navy" }}
              >
                Sign up
  </Button>*/}
              </Link>
            </Flex>
          </Box>
        </Flex>
        <Spacer />
        <Box>
          {points.map((p) => {
            return (
              <Flex>
                <Flex m={3} justifyContent="center" alignItems="center">
                  {p.icon}
                </Flex>
                <Box m={3}>
                  <Heading size="md">{p.heading}</Heading>
                  <Text>{p.desc}</Text>
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Flex>

      <Box bg="pt-bg" w="100%" p={3} borderRadius={9} textAlign="center">
        <Text fontSize="lg" fontWeight="bold">
          Interested in using PhacoTrainer? Fill out{" "}
          <Link
            href="https://docs.google.com/forms/d/e/1FAIpQLSfEIHWeOWAGrgWK4b1gtutpJafcXdZfa1dPouBFWDDZthIdzw/viewform?usp=sf_link"
            isExternal
            color="pt-blue"
            textDecoration="underline"
            _hover={{ color: "blue" }}
          >
            this form
          </Link>{" "}
          to get access!
        </Text>
      </Box>

      <Box my={6} id="demo">
        <Heading m={3} size="sm">
          PhacoTrainer Demo
        </Heading>
        <Box borderRadius={9} borderWidth={3} p={3}>
          <VideoPlayer
            url={
              "https://firebasestorage.googleapis.com/v0/b/phacotrainer.appspot.com/o/HMPQ54eZVKYGCHTJGtlXpOqE9fT2%2Fa11a12ef-4e1f-4852-b2f0-dc6b88fbf55f.mp4?alt=media"
            }
            videoID="a11a12ef-4e1f-4852-b2f0-dc6b88fbf55f"
            demo
          />
        </Box>
      </Box>

      <Box my={9}>
        <Heading textAlign="center" size="md">
          How PhacoTrainer Works
        </Heading>
        <Heading textAlign="center" m={3}>
          Auto-Analyzed Cataract Surgery
        </Heading>
        <Flex justifyContent="center" alignItems="center">
          {steps.map((s, i) => {
            return (
              <Box key={i} m={3}>
                <Text textAlign="center" fontSize="lg">
                  Step {i + 1}:
                </Text>
                <Heading size="md" textAlign="center">
                  {s.heading}
                </Heading>
                <Flex alignItems="center" justifyContent="center" m={3}>
                  <Flex height={223} width={223}>
                    {s.image}
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Box>

      {/*<Box my={9} id="FAQs">
        <Heading textAlign="center">FAQs</Heading>

        <Accordion allowMultiple>
          {FAQs.map((i) => {
            return (
              <AccordionItem key={i}>
                <h2>
                  <AccordionButton>
                    <Box textAlign="left" flex={1}>
                      {i.q}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{i.a}</AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
        </Box>*/}
    </Layout>
  );
};

export default Landing;
