import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { app } from "../../firebase-config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  getDocs,
} from "firebase/firestore";
import Layout from "../layout/index";

const Register = () => {
  const [user, setUser] = useState({ email: "", password: "", fullName: "" });
  const [status, setStatus] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);

  // Check if email ends with @stanford.edu
  const validateEmail = (email) => {
    const e = email.toLowerCase();
    const whitelist = [
      "julia.xia@cuanschutz.edu",
      "ross.campbell.ucsd@gmail.com",
    ];

    if (whitelist.includes(e)) return true;

    return e.endsWith("@stanford.edu");
  };

  const createAccount = async (e) => {
    setIsActive(true);
    e.preventDefault();

    if (!validateEmail(user.email)) {
      setError("Please use a @stanford.edu email");
      setIsActive(false);
      return;
    }

    const authentication = getAuth();
    const db = getFirestore();

    try {
      let response = await createUserWithEmailAndPassword(
        authentication,
        user.email,
        user.password
      );

      let uid = response.user.uid;
      await setDoc(doc(db, "users", uid), {
        uid: uid,
        timestamp: new Date().getTime(),
        fullName: user.fullName,
        email: user.email,
      });

      setIsActive(false);
      setStatus(true);
    } catch (error) {
      console.log("Error:", error);
      setError(true);
    }
  };

  return (
    <Layout noFooter>
      <Row className="login-form">
        <Col sm={12} md={6} className={"align-self-center"}>
          <Form onSubmit={createAccount}>
            {status && (
              <Alert variant="success">
                Account Created Successfully! Login to continue.
              </Alert>
            )}
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                required
                value={user.fullName}
                onChange={(e) => setUser({ ...user, fullName: e.target.value })}
                placeholder="Enter full name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                required
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                placeholder="Password"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Create Account
            </Button>
          </Form>
          <br />
          {error && (
            <Alert variant="danger">
              {" "}
              Error creating account. Email may be invalid, account may already
              exist, or you may not have permissions to create an account.
              Please try again.{" "}
            </Alert>
          )}
          <a href="/login">
            Existing User? <u>Login</u>
          </a>
        </Col>
        <Col md={6}>
          <img id="auth" src="/bg.png" alt="image" />
        </Col>
      </Row>
    </Layout>
  );
};

export default Register;
