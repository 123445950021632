import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Link,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  CircularProgress,
  RadioGroup,
  Radio,
  Stack,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  FiUpload,
  FiMoreHorizontal,
  FiEdit,
  FiCalendar,
  FiTrash2,
} from "react-icons/fi";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../../dashboard.scss";
import { key, findLabelIndex } from "../../lib/index";
import { SearchWrapper } from "../search-wrapper";

const storage = getStorage();
const db = getFirestore();
const auth = getAuth();

// TODO: Known issue is that on first login, a users videos will not load and a refresh is required to load the videos
const Upload = () => {
  const [videos, setVideos] = useState([]);
  const [insights, setInsights] = useState([]);
  const [uid, setUid] = useState(sessionStorage.getItem("localId") || null);

  const [loading, setLoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState();

  const [sorting, setSorting] = useState("surgery-date");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0); // Number of videos uploaded for loading indicator
  const [fileCount, setFileCount] = useState(0); // Number of videos given to upload
  const toast = useToast();
  const [mergeUploadModalOpen, setMergeUploadModalOpen] = useState(false);
  const [videoSegments, setVideoSegments] = useState([]);

  // Disclosures for rename, change date, and delete modals
  const {
    isOpen: renameIsOpen,
    onOpen: renameOnOpen,
    onClose: renameOnClose,
  } = useDisclosure();
  const {
    isOpen: dateIsOpen,
    onOpen: dateOnOpen,
    onClose: dateOnClose,
  } = useDisclosure();

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  // State of the currently selected video, keeps track of the video with the most recently "3 dots button" clicked
  const [activeName, setActiveName] = useState();
  const [activeId, setActiveId] = useState();

  const ChooseSort = () => {
    return (
      <RadioGroup onChange={setSorting} value={sorting}>
        <Stack direction="row">
          <Radio value="surgery-date">surgery date</Radio>
          <Radio value="upload-date">upload date</Radio>
          <Radio value="duration">duration</Radio>
          <Radio value="name">filename</Radio>
        </Stack>
      </RadioGroup>
    );
  };

  const FilterDate = () => {
    return (
      <Flex direction="row" width="fit-content" gap={4}>
        <Flex direction="column">
          <Text fontSize="xs" fontWeight="bold" margin={0}>
            Start Date
          </Text>
          <Input
            type="date"
            value={startDate}
            onChange={(i) => {
              setStartDate(i.target.value);
            }}
          />
        </Flex>

        <Flex direction="column">
          <Text fontSize="xs" fontWeight="bold" margin={0}>
            End Date
          </Text>
          <Input
            disabled={!startDate}
            type="date"
            min={startDate}
            value={endDate}
            onChange={(i) => {
              setEndDate(i.target.value);
            }}
          />
        </Flex>
      </Flex>
    );
  };

  const datesMatchDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const runDateRangeFilter = (video) => {
    if (!startDate) return true;

    const convertedStartDate = new Date(startDate + "T00:00:00");
    const convertedEndDate = endDate
      ? new Date(endDate + "T00:00:00")
      : new Date();
    const videoTimestampDate = video.timestamp.toDate();

    const startDateDayMatch = startDate
      ? datesMatchDay(video.timestamp.toDate(), convertedStartDate)
      : false;

    const endDateDayMatch = endDate
      ? datesMatchDay(video.timestamp.toDate(), convertedEndDate)
      : false;

    if (startDateDayMatch) return true;
    if (
      convertedStartDate &&
      videoTimestampDate > convertedStartDate &&
      videoTimestampDate < convertedEndDate
    )
      return true;

    if (convertedStartDate && endDateDayMatch) return true;
    if (
      convertedStartDate &&
      videoTimestampDate > startDate &&
      videoTimestampDate < convertedEndDate
    )
      return true;

    return false;
  };

  const runSearchVideoFilter = (video) => {
    // No search, show video
    if (!searchResults) return true;

    // Otherwise, search results
    const foundSearchResult = searchResults.find(
      (result) => result.videoId === video.videoId
    );

    if (foundSearchResult) return true;

    return false;
  };

  const sort = (v1, v2) => {
    if (sorting === "upload-date") {
      return v2.creationDate - v1.creationDate;
    } else if (sorting === "surgery-date") {
      return v2.timestamp - v1.timestamp;
    } else if (sorting === "duration") {
      return v2.duration - v1.duration;
    } else if (sorting === "name") {
      const b = v2.filename.toUpperCase();
      const a = v1.filename.toUpperCase();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    }
  };

  const navigate = useNavigate();

  const fetchVideos = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(
        // Sophia: GoiFQQPhxuf3O6f50PLkaB2h7q92
        // Julia: CjU46km8maVuPy7DAe6cEwW4yh23
        // Tony: qyyHrTchZ3T4mmDAwJpZnRNWYkG3
        query(collection(db, "videos"), where("uid", "==", uid))
      );

      const fetchedVideos = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        docId: doc.id,
      }));

      setVideos(fetchedVideos);
    } catch (error) {
      toast({
        title: "Error fetching videos",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error fetching videos:", error);
    }
  }, [db, uid]);

  const fetchInsights = useCallback(async () => {
    const COLLECTION = "insights";

    try {
      const querySnapshot = await getDocs(
        // Sophia: GoiFQQPhxuf3O6f50PLkaB2h7q92
        // Julia: CjU46km8maVuPy7DAe6cEwW4yh23
        // Tony: qyyHrTchZ3T4mmDAwJpZnRNWYkG3
        query(collection(db, COLLECTION), where("uid", "==", uid))
      );

      const items = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        docId: doc.id,
      }));

      setInsights(items);
    } catch (error) {
      toast({
        title: "Error fetching insights",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error("Error fetching insights:", error);
    }
  }, [toast, uid]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       setUid(user.uid);
  //       sessionStorage.setItem("localId", user.uid);
  //     } else {
  //       setUid(null);
  //     }
  //   });
  //   return () => unsubscribe(); // Cleanup on component unmount
  // }, []);

  useEffect(() => {
    let authToken = sessionStorage.getItem("Auth Token");

    if (!authToken) {
      navigate("/login");
    }

    // Made a fix with a new useEffect in attempt to solve issue where videos don't load on first load, needed refresh
    // If doesn't work may have to revert to this method of setting uid
    //setUid(sessionStorage.getItem("localId")); // user ID

    fetchVideos();
    fetchInsights();
  }, [navigate, uid, fetchVideos, fetchInsights]);

  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "90px",
    borderWidth: 3,
    borderRadius: "30px",
    height: "300px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    transition: "border .3s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  // Shared among the basic upload and merge segment upload
  const uploadVideo = async (
    vid,
    uniqueId,
    progressCallback,
    maxRetries = 3
  ) => {
    const fileExtension = vid.name.split(".").pop().toLowerCase();

    // console.log(uid); // TODO: look into being undefed for gmail?
    const videoRef = storageRef(storage, `${uid}/${uniqueId}.${fileExtension}`);
    const uploadTask = uploadBytesResumable(videoRef, vid);

    return new Promise((resolve, reject) => {
      let retryCount = 0;

      const uploadHandler = () => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressCallback(progress);
          },
          (error) => {
            console.error("Upload failed:", vid, error.message);
            if (retryCount < maxRetries) {
              retryCount++;
              console.log(`Retrying upload (attempt ${retryCount})...`);
              uploadHandler();
            } else {
              reject(error);
            }
          },
          () => {
            getDownloadURL(videoRef)
              .then((downloadURL) => {
                console.log(`Upload successful for ${vid.name}`);
                resolve(downloadURL);
              })
              .catch((error) => {
                reject(error);
              });
          }
        );
      };

      uploadHandler();
    });
  };

  // Shared among the basic upload and merge segment upload
  const addVidFirestore = (filename, uniqueId, videoSegmentData = null) => {
    // console.log(filename);
    const date = new Date();
    const videoData = {
      filename: filename,
      creationDate: date,
      timestamp: date,
      uid: uid,
      videoId: uniqueId,
      processed: false,
      videoSegmentData: videoSegmentData, // an object that will only exist if the video is a parent of some other video segments
    };
    return setDoc(doc(db, "videos", `${uniqueId}`), videoData);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setLoading(true);
      setUploadModalOpen(true); // Open the upload modal
      setUploadedCount(0); // Number of videos that have been uploaded
      setFileCount(acceptedFiles.length);

      const uploadedVideos = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];

        const fileExtension = file.name.split(".").pop().toLowerCase();

        const uniqueId = uuidv4();
        const downloadURL = await uploadVideo(
          file,
          uniqueId,
          setUploadProgress
        );

        // Ensure that there is a uid before uploading
        if (uid === null) {
          throw "No uid referenced";
        }

        const newVideoData = {
          filename: file.name,
          creationDate: Timestamp.fromDate(new Date()),
          timestamp: Timestamp.fromDate(new Date()),
          uid: uid,
          videoId: uniqueId,
          processed: false,
          // Add other relevant video properties here
        };
        await addVidFirestore(file.name, uniqueId);
        uploadedVideos.push({ ...newVideoData, docId: uniqueId, downloadURL }); // Use uniqueId as docId
        setUploadedCount((count) => count + 1);
      }

      setVideos((prevVideos) => [...prevVideos, ...uploadedVideos]);
    } catch (error) {
      toast({
        title: "Video uploads failed",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Video uploads failed:", error);
    } finally {
      setLoading(false);
      fetchVideos(); // Show the new uploaded videos
      setUploadModalOpen(false); // Close the upload modal once the upload is complete
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    //maxFiles:2,
    onDrop,
    accept: {
      "video/mp4": [".mp4"],
      "video/mpg": [".mpg"],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const RenameModal = () => {
    const [name, setName] = useState();

    const rename = async () => {
      await updateDoc(doc(db, "videos", activeId), {
        filename: name,
      })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          toast({
            title: "Error renaming file",
            description: e.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          console.log("Error renaming file");
          console.error(e);
        });
    };

    return (
      <Modal isOpen={renameIsOpen} onClose={renameOnClose}>
        <ModalContent>
          <ModalHeader>Rename {activeName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input
              value={name}
              onChange={(i) => {
                const v = i.target.value;
                if (
                  /^[a-zA-Z0-9.]*$/.test(v) &&
                  v.length - v.replaceAll(".", "").length <= 1
                ) {
                  // Only allow alphanumeric, no spaces for filenames, only 1 period
                  setName(i.target.value);
                }
              }}
              placeholder="Filename"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => rename()}
              isDisabled={name == null}
            >
              Rename
            </Button>
            <Button onClick={renameOnClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const ChangeDateModal = () => {
    const [date, setDate] = useState();

    const changeDate = async () => {
      await updateDoc(doc(db, "videos", activeId), {
        timestamp: Timestamp.fromDate(new Date(date)),
      })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          toast({
            title: "Error changing video date",
            description: e.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          console.log("Error changing file date");
          console.error(e);
        });
    };

    return (
      <Modal isOpen={dateIsOpen} onClose={dateOnClose}>
        <ModalContent>
          <ModalHeader>Rename {activeName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input
              type="datetime-local"
              value={date}
              onChange={(i) => {
                setDate(i.target.value);
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => changeDate()}
              isDisabled={date == null}
            >
              Change Date
            </Button>
            <Button onClick={dateOnClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const DeleteModal = () => {
    const deleteVideo = async () => {
      try {
        await deleteDoc(doc(db, "videos", activeId));
        fetchVideos(); // Fetch videos after deleting
        deleteOnClose(); // Close the modal
      } catch (e) {
        console.log("Error deleting file");
        console.error(e);
      }
    };

    const markForDeletion = async () => {
      try {
        await addDoc(collection(db, "deletedVideos"), {
          id: activeId,
          deleted: true,
          timestamp: Timestamp.fromDate(new Date()),
        });
        deleteVideo();
      } catch (e) {
        toast({
          title: "Error deleting video",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        console.log("Error marking video for deletion");
        console.error(e);
      }
    };

    return (
      <Modal isOpen={deleteIsOpen} onClose={deleteOnClose}>
        <ModalContent>
          <ModalHeader>
            Are you sure you want to delete {activeName}?
          </ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={markForDeletion}>
              Delete
            </Button>
            <Button onClick={deleteOnClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  function sumInsightDurations({ insights }) {
    let milliseconds = 0;

    if (insights?.length) {
      insights.forEach((insight) => {
        milliseconds += insight.duration;
      });
    }

    return milliseconds;
  }

  function convertMillisToTime(millis) {
    // Convert milliseconds to seconds
    const seconds = millis / 1000;

    // Get the number of hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    // Format the time as a string
    const formattedTime = `${hours}h:${minutes}m:${Math.round(secondsLeft)}s`;

    // Return the formatted time
    return formattedTime;
  }

  // The id being passed may not actually be the video ID?
  const VideoItem = ({
    name,
    uid,
    timestamp,
    uploadDate,
    id,
    docId,
    unprocessed = false,
    insights = [],
  }) => {
    const duration = sumInsightDurations({ insights });
    const formattedDuration = convertMillisToTime(duration);

    const formatDate = (date) =>
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

    timestamp = formatDate(timestamp);
    // console.log(name, timestamp)
    uploadDate = formatDate(uploadDate);

    return (
      <Center
        key={id}
        p={3}
        _hover={{ bg: "whitesmoke", shadow: "sm" }}
        borderRadius={6}
        width="100%"
      >
        <Box width="100%">
          <Link href={`/video/${uid}/${id}`} style={{ display: "contents" }}>
            {
              // If processed, show the image preview
              !unprocessed && (
                <Box>
                  <img
                    width="200"
                    alt="Preview not found."
                    src={`https://firebasestorage.googleapis.com/v0/b/phacotrainer.appspot.com/o/${uid}%2F${id}.jpg?alt=media`}
                  />
                </Box>
              )
            }
            <Text
              width="100%"
              paddingTop={2}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {name}
            </Text>
          </Link>
          <Flex>
            <Box>
              <Box>
                <Text fontSize="xs" noOfLines={1} margin="0" textAlign="left">
                  Surgery date: {timestamp}
                </Text>
                <Text
                  fontSize="xs"
                  noOfLines={1}
                  color="grey"
                  margin="0"
                  textAlign="left"
                >
                  Upload date: {uploadDate}
                </Text>

                {duration && (
                  <Text
                    fontSize="xs"
                    noOfLines={1}
                    color="grey"
                    margin="0"
                    textAlign="left"
                  >
                    Duration: {formattedDuration}
                  </Text>
                )}
              </Box>
            </Box>
            <Center>
              <Menu>
                <MenuButton
                  m={1}
                  p={1}
                  transition="all 0.2s"
                  //_hover={{ bg: 'gray.400' }}
                  _expanded={{ bg: "blue.400" }}
                  _focus={{ boxShadow: "outline" }}
                  variant="link"
                  onClick={() => {
                    setActiveName(name);
                    setActiveId(docId);
                    //console.log(name, docId);
                  }}
                >
                  <FiMoreHorizontal />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={renameOnOpen}>
                    <Box mx={2}>
                      <FiEdit />
                    </Box>
                    Rename Video
                  </MenuItem>
                  <MenuItem onClick={dateOnOpen}>
                    <Box mx={2}>
                      <FiCalendar />
                    </Box>
                    Update Date
                  </MenuItem>
                  <MenuItem onClick={deleteOnOpen}>
                    <Box mx={2}>
                      <FiTrash2 />
                    </Box>
                    Delete Video
                  </MenuItem>
                </MenuList>
              </Menu>
            </Center>
          </Flex>
        </Box>
      </Center>
    );
  };

  const onDragEndVideoSegments = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(videoSegments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setVideoSegments(items);
  };
  const removeSegment = (id) => {
    setVideoSegments((currentSegments) =>
      currentSegments.filter((segment) => segment.id !== id)
    );
  };

  const handleUploadSegments = async () => {
    // console.log("Starting segment upload...");
    if (videoSegments.length === 0) {
      console.log("No video segments to upload.");
      return;
    }

    setLoading(true);
    setUploadedCount(0);
    setFileCount(videoSegments.length);
    try {
      const uploadPromises = videoSegments.map((segment, index) => {
        const uniqueId = uuidv4();
        return uploadVideo(segment.file, uniqueId, setUploadProgress)
          .then((downloadURL) => ({
            order: index + 1,
            filename: segment.name,
            uniqueId,
          }))
          .then(setUploadedCount((count) => count + 1));
      });

      const uploadedSegments = await Promise.all(uploadPromises);
      // console.log(uploadedSegments)

      // Assuming the first segment is the parent, update Firestore with videoSegment data
      const parentSegment = uploadedSegments[0];
      const videoSegmentData = {
        isParent: true,
        segments: uploadedSegments.map(({ order, uniqueId }) => ({
          order,
          videoId: uniqueId,
        })),
      };
      // TODO: here ish? create new uid for vid to be used?

      await addVidFirestore(
        parentSegment.filename,
        parentSegment.uniqueId,
        videoSegmentData
      );

      // // Wait we don't even want to the children videos as seperate entries, we'll eventually combine them
      // for (let i = 1; i < uploadedSegments.length; i++) {
      //   const currVideo = uploadedSegments[i];
      //   await addVidFirestore(currVideo.filename, currVideo.uniqueId);
      // }

      toast({
        title: "Video segments uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading video segments:", error);
      toast({
        title: "Error uploading video segments",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setMergeUploadModalOpen(false); // Close the modal
      fetchVideos(); // Refresh the videos list to show newly uploaded segments
    }
  };

  const VideoSegmentsDropzone = ({ onSegmentsAdded }) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        // Create a new list of files with additional data for reordering
        const filesWithPreview = acceptedFiles.map((file) => ({
          id: uuidv4(), // Assign a unique ID for key prop and reordering
          name: file.name,
          file: file,
        }));

        onSegmentsAdded(filesWithPreview);
      },
      [onSegmentsAdded]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <div
        {...getRootProps()}
        className="dropzone"
        style={{
          padding: "20px",
          border: "2px dashed #007bff",
          marginBottom: "20px",
        }}
      >
        <input {...getInputProps()} />
        <p>Drag and drop video segments here, or click to select files.</p>
        <p>
          These files will be combined into 1 video, reorder them as necessary.
        </p>
      </div>
    );
  };

  const searchResultsCallback = ({ results, query }) => {
    setSearchResults(query ? results : null);
  };

  return (
    <Box className="upload-video">
      <Box mb={50}>
        <Flex justify="space-between">
          <Flex>
            <Button
              mx={3}
              size="lg"
              leftIcon={<FiUpload />}
              onClick={() => setUploadModalOpen(true)}
            >
              Upload Video
            </Button>
            <Button
              mx={3}
              size="lg"
              leftIcon={<FiUpload />}
              onClick={() => setMergeUploadModalOpen(true)}
            >
              Merge & Upload Video Segments
            </Button>
          </Flex>

          <Flex>
            <SearchWrapper uid={uid} resultsCallback={searchResultsCallback} />
          </Flex>
        </Flex>
        {/* Segment combine upload modal */}
        <Modal
          isOpen={mergeUploadModalOpen}
          onClose={() => setMergeUploadModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Upload & Merge Video Segments</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!loading ? (
                <>
                  <VideoSegmentsDropzone
                    onSegmentsAdded={(newSegments) =>
                      setVideoSegments((currentSegments) => [
                        ...currentSegments,
                        ...newSegments,
                      ])
                    }
                  />
                  <DragDropContext onDragEnd={onDragEndVideoSegments}>
                    <Droppable droppableId="segments">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {videoSegments.map((segment, index) => (
                            <Draggable
                              key={segment.id}
                              draggableId={segment.id}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  p={2}
                                  mb={2}
                                  bg="gray.100"
                                >
                                  <Text>{segment.name}</Text>
                                  <Button
                                    size="xs"
                                    onClick={() => removeSegment(segment.id)}
                                  >
                                    x
                                  </Button>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                <>
                  <Box>
                    <Center m={3}>
                      <CircularProgress value={uploadProgress} />
                    </Center>
                    <Center>
                      <Text>{`${uploadedCount}/${fileCount} videos uploaded`}</Text>
                    </Center>
                    <Text fontSize="lg">
                      Uploading, do not leave or refresh the page.{" "}
                    </Text>
                    <Text fontSize="lg">This may take several minutes.</Text>
                  </Box>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                onClick={() => setMergeUploadModalOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleUploadSegments}>
                Upload
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Basic Upload Modal */}
        <Modal
          isOpen={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Upload Surgical Recordings</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!loading ? (
                <Box className={"files-upload-wrapper"}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <Text>Drop files</Text>
                    ) : (
                      <div className="upload-icon-wrapper">
                        <Text align="center">
                          Click to browse or drag and drop your file(s)
                        </Text>
                        <Center>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-upload"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                        </Center>
                      </div>
                    )}
                  </div>
                </Box>
              ) : (
                <Box>
                  <Center m={3}>
                    <CircularProgress value={uploadProgress} />
                  </Center>
                  <Center>
                    <Text>{`${uploadedCount}/${fileCount} videos uploaded`}</Text>
                  </Center>
                  <Text fontSize="lg">
                    Uploading, do not leave or refresh the page.{" "}
                  </Text>
                  <Text fontSize="lg">This may take several minutes.</Text>
                </Box>
              )}
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" onClick={() => window.location.reload()}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      <Box mb={50}>
        <Box>
          <Heading size="md">Processed Videos</Heading>
          {videos.filter((v) => v.processed).length === 0 ? (
            <Text>
              PhacoTrainer has yet to analyze one of your videos. Please upload
              one and wait 12 hours for it to be processed.{" "}
              <Text>
                In the meantime, check out{" "}
                <Link color="pt-navy" href="/#demo">
                  the demo
                </Link>{" "}
                to view an example.
              </Text>
            </Text>
          ) : (
            <Box>
              <Text size="lg" maxWidth={500}>
                PhacoTrainer has processed the following{" "}
                {videos.filter((v) => v.processed).length} videos.<br></br>{" "}
                Select one to analyze it and see insights!
              </Text>

              <Flex py={3} direction="column">
                <Text size="md" margin="0">
                  Search videos by surgery step
                </Text>
                <Select
                  width="fit-content"
                  placeholder="Select step"
                  onChange={(i) => {
                    setSelectedStep(i.target.value);
                  }}
                >
                  {key.map((i) => {
                    return (
                      <option key={i.label} value={i.label}>
                        {i.label}
                      </option>
                    );
                  })}
                </Select>
              </Flex>

              <Flex
                direction="row"
                gap={6}
                padding="20px 0 0"
                justify={"space-between"}
              >
                <Flex direction="column" gap={1} width="100%">
                  <Heading size="xs" margin={0}>
                    Sort By
                  </Heading>

                  <ChooseSort />
                </Flex>

                <Flex direction="column" gap={2} width="100%">
                  <Heading size="xs" margin={0}>
                    Filter by Date
                  </Heading>

                  <FilterDate />
                </Flex>
              </Flex>
            </Box>
          )}
        </Box>
        <Flex direction="column" padding="20px 0 0">
          {videos.filter((v) => v.processed).length > 0 && (
            <SimpleGrid
              minChildWidth="190px"
              spacing="30px"
              width="100%"
              borderRadius={6}
              shadow="md"
              bg="white"
              p={3}
            >
              {videos &&
                videos
                  .filter((v) => v.processed)
                  .filter((video) => runSearchVideoFilter(video))
                  .filter((v) =>
                    selectedStep
                      ? v.metadata.surgery_steps.includes(selectedStep)
                      : true
                  )
                  .filter((video) => runDateRangeFilter(video))
                  .map((video) => {
                    const videoInsights = insights.filter(
                      (insight) => insight.videoId === video.videoId
                    );

                    const payload = {};
                    if (videoInsights?.length) {
                      const duration = sumInsightDurations({
                        insights: videoInsights,
                      });

                      payload.duration = duration;
                    }

                    return { ...video, ...payload };
                  })
                  .sort((v1, v2) => sort(v1, v2))
                  .map((video) => {
                    const videoInsights = insights.filter(
                      (insight) => insight.videoId === video.videoId
                    );

                    return (
                      <VideoItem
                        name={video.filename}
                        uid={video.uid}
                        key={video.videoId}
                        timestamp={video.timestamp.toDate()}
                        uploadDate={video.creationDate.toDate()}
                        id={video.videoId}
                        docId={video.docId}
                        insights={videoInsights}
                      />
                    );
                  })}
            </SimpleGrid>
          )}
        </Flex>
      </Box>
      <Box>
        <Box mb={3}>
          <Heading size="md">Unprocessed videos</Heading>

          {videos.filter((v) => !v.processed).length == 0 ? (
            <Text>PhacoTrainer has processed all of your videos!</Text>
          ) : (
            <Box>
              <Flex>
                <Center mx={3}>
                  <Spinner speed="0.9s" color="pt-blue" />
                </Center>
                <Text size="md" my={3}>
                  PhacoTrainer is processing these videos. They will be ready
                  for analysis ~12 hours from upload.
                </Text>
              </Flex>
              <Flex>
                <Text mr={3}>Sort videos by</Text>
                <ChooseSort />
              </Flex>
            </Box>
          )}
        </Box>

        {videos.filter((v) => !v.processed).length > 0 && (
          <SimpleGrid
            minChildWidth="190px"
            spacing="30px"
            borderRadius={6}
            shadow="md"
            bg="white"
            p={3}
          >
            {videos &&
              videos
                .filter((v) => !v.processed)
                .sort((v1, v2) => sort(v1, v2))
                .map((video) => {
                  return (
                    <VideoItem
                      name={video.filename}
                      uid={video.uid}
                      key={video.videoId + "unprocessed"}
                      timestamp={video.timestamp.toDate()}
                      uploadDate={video.creationDate.toDate()}
                      id={video.videoId}
                      docId={video.docId}
                      unprocessed
                    />
                  );
                })}
          </SimpleGrid>
        )}
      </Box>

      {/* Modals for videos */}
      <RenameModal />
      <ChangeDateModal />
      <DeleteModal />
    </Box>
  );
};

export default Upload;
