import { useParams } from "react-router";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Box, Text, Flex, Heading, CircularProgress } from "@chakra-ui/react";
import Layout from "../layout/index";
import VideoPlayer from "../videoPlayer";

const Video = () => {
  let { uid, file } = useParams();

  let fileName = `${file}.mp4`;

  let fullName = sessionStorage.getItem("fullName");

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("Auth Token");
    navigate("/login");
  };

  return (
    <Layout noFooter>
      {/*<div className="sidebar">
        <h6 style={{ color: "#fff", padding: "20px" }}>PhacoTrainer</h6>
        <div style={{ color: "#fff", padding: "20px" }}>
          <h4>Welcome</h4>
          <h5>{fullName}</h5>
        </div>
        <div style={{ marginTop: "7em" }}>
          <a href="/">Upload</a>
          <a onClick={handleLogout}>Logout</a>
        </div>
  </div>*/}

      {/*<div className="content">
        <Container style={{ marginTop: "2em" }}>
          <Row style={{ marginTop: "5em" }}>
            <Col sm={12} md={12} key={uid}>
              <video
                width="760"
                height="480"

                controls
              >
                Your browser does not support the video tag.
              </video>
            </Col>
          </Row>
        </Container>
  </div>*/}
      <Box>
        <VideoPlayer
          url={`https://firebasestorage.googleapis.com/v0/b/phacotrainer.appspot.com/o/${uid}%2F${fileName}?alt=media`}
          videoID={file}
          uid={uid}
        />

        {/*<div id="comment-form">
          <div className="form-group">
            <textarea name="comment" className={"form-control"} id="comment" cols="30" rows="10"></textarea>
          </div>
          <div className="form-group text-center">
            <input type="submit" className={"btn btn-primary"} value={"Post Comment"}/>
          </div>
</div>*/}
      </Box>
    </Layout>
  );
};

export default Video;
