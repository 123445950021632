import {
  ChakraProvider,
  extendTheme,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import "@fontsource/epilogue";
import "@fontsource/inter";

const theme = extendTheme({
  fonts: {
    heading: "'Epilogue', sans-serif",
    body: "'Inter', sans-serif",
  },
  colors: {
    "pt-bg": "#F6FAFD",
    "pt-blue": "#0168FF",
    "pt-navy": "#012D9B",
  },
});

const layout = ({ children, noFooter = false }) => {
  return (
    <ChakraProvider theme={theme}>
     <Flex justifyContent="center" width="100%"  bg="pt-bg">
        <Box width={["100%", "90%", "75%"]}>
          <Header />
          <Center id={"main-content"}>
            <Box>{children}</Box>
          </Center>
          {!noFooter && <Footer />}
          
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default layout;
