import {
  Heading,
  Flex,
  Box,
  Center,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  HStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiBarChart, FiUpload, FiSettings, FiPower } from "react-icons/fi";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

// Set of links only shown when logged in
const links = [
  { text: "Dashboard", href: "/dashboard" },
  // { text: "FAQs", href: "/#FAQs" },
];

const Header = () => {
  const [authToken, setAuthToken] = useState(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      } else {
        setEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setAuthToken(sessionStorage.getItem("Auth Token"));
  }, []);

  const logout = () => {
    sessionStorage.removeItem("Auth Token");
    navigate("/login");
  };

  return (
    <Flex
      as="header"
      p={3}
      alignItems="center"
      justifyContent="space-between"
      bg="pt-bg"
      color="grey"
      borderWidth={3}
      borderTopWidth={0}
    >
      <Box display="flex" alignItems="center">
        <ChakraLink href="/" color="pt-blue">
          <Heading size="md" my="auto">PhacoTrainer</Heading>
        </ChakraLink>
      </Box>

      <HStack spacing={4}>
        {authToken &&
          links.map((link) => (
            // Only shown when logged in
            // Set of links with no icons
            <Button key={link.text} onClick={() => navigate(link.href)}>
              {link.text}
            </Button>
          ))}

        {!authToken ? (
          // This is shown when the user is not logged in
          <>
            <ChakraLink href="/login">
              <Button bg="transparent">Sign in</Button>
            </ChakraLink>
            <ChakraLink href="/register">
              <Button>Sign up</Button>
            </ChakraLink>
          </>
        ) : (
          // Shown when the user is logged in
          <>
            <ChakraLink href="/videos">
              <Button leftIcon={<FiUpload />}>Surgery Videos</Button>
            </ChakraLink>
            <Popover>
              <PopoverTrigger>
                <Button ml={2} leftIcon={<FiSettings />}>
                  Profile
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <Box>
                    <Text>Email: {email}</Text>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Button onClick={logout} leftIcon={<FiPower />}>
              Sign out
            </Button>
          </>
        )}
      </HStack>
    </Flex>
  );
};

export default Header;
