export const key = [
  /*{
      label: "no label",
      color: "#123456",
    },*/
  {
    label: "create wounds",
    color: "#B36305",
  },

  {
    label: "close wounds",
    color: "#EE7C0E",
  },
  {
    label: "capsulorhexis",
    color: "#00782A",
  },
  {
    label: "hydrodissection",
    color: "#9364CD",
  },
  {
    label: "phacoemulsification",
    color: "#2DAE73",
  },
  {
    label: "irrigation/aspiration",
    color: "#F3A9BB",
  },
  {
    label: "remove viscoelastic",
    color: "#8490F0",
  },
  {
    label: "trypan blue staining",
    color: "#123456",
  },
  {
    label: "subconjunctival injection",
    color: "#003688",
  },
  {
    label: "chamber injection",
    color: "#0098D4",
  },
  {
    label: "anterior chamber injection",
    color: "#0098D4",
  },
  {
    label: "iris manipulation",
    color: "#00A4A7",
  },
  {
    label: "place lens",
    color: "#95CDBA",
  },
  {
    label: "advanced technique",
    color: "#95CDBA",
  },
];

export const findLabelIndex = (label) => {
  for (let i = 0; i < key.length; i++) {
    if (key[i].label === label) return i;
  }
};

export const formatTime = (seconds) => {
  const pad = (string) => {
    return ("0" + string).slice(-2);
  };

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};
