import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Flex,
  Heading,
  Grid,
  GridItem,
  Select,
  Stat,
  StatArrow,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
} from "recharts";
import "../../dashboard.scss";
import { key, findLabelIndex } from "../../lib/index";

const Overview = () => {
  const storage = getStorage();
  const db = getFirestore();

  const [uid, setUid] = useState(sessionStorage.getItem("localId"));
  const [loading, setLoading] = useState(false);

  const [videos, setVideos] = useState([]);
  const [insights, setInsights] = useState([]); // For operating time graphs
  const [modelOutputs, setModalOutputs] = useState([]); // For path length, max velocity, and centration graphs
  const [selectedStep, setSelectedStep] = useState();
  const [graphAxisDisplay, setGraphAxisDisplay] = useState([
    "filename",
    "date",
  ]);

  const navigate = useNavigate();

  //let uid = sessionStorage.getItem("localId");

  const fetchVideos = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "videos"),
        where("processed", "==", true),
        where("uid", "==", uid)
      )
    );

    querySnapshot.forEach((doc) => {
      const date = doc.data().timestamp.toDate();
      setVideos((videos) => [
        ...videos,
        {
          ...doc.data(),
          ...{
            xAxisLabel: date.getMonth() + 1 + "/" + date.getDate(),
          },
        },
      ]);
    });
  };

  // For operating times graphs
  const fetchInsights = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "insights"), where("uid", "==", uid))
    );

    const newInsights = querySnapshot.docs.map((doc) => doc.data());

    setInsights(newInsights);
  };

  // For phacometrics outputs
  const fetchModelOutputs = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "modelOutput"),
        where("uid", "==", uid)
        //where("phacometrics", "!=", null) // Maybe add this?
      )
    );

    const newModelOutputs = querySnapshot.docs
      .map((doc) => doc.data())
      .filter((doc) => doc.phacometrics !== undefined); // Only want documents that have phacometrics

    setModalOutputs(newModelOutputs);
  };

  useEffect(() => {
    let authToken = sessionStorage.getItem("Auth Token");

    if (!authToken) {
      navigate("/login");
    }

    //setUid(sessionStorage.getItem("localId")); // user ID

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUid(user.uid);
        setVideos([]);
        fetchVideos();
        fetchInsights();
        fetchModelOutputs();
      }
    });
  }, []);

  const generateFilteredInsights = (insights) => {
    // Given a videoId, return whether it exists or not.
    const videoExists = (videoId) => {
      for (const video of videos) {
        if (video.videoId === videoId) {
          return true;
        }
      }
      return false;
    };

    // Given a videoId, return its timestamp (surgery date)
    const videoTimestamp = (videoId) => {
      for (const video of videos) {
        if (video.videoId === videoId) {
          return video.timestamp.toDate();
        }
      }

      console.error(
        "Could not find video timestamp for graph rendering for video " +
          videoId
      );
    };
    // Sort and filter the insights
    let i = insights
      .filter((i) => {
        // Only show non empty steps
        return i.duration !== 0;
      })
      .filter((i) => {
        // Only show videos who exist
        // This filter exists because insights can exist for deleted videos
        return videoExists(i.videoId);
      })
      .filter((i) => {
        return i.surgeryStep === selectedStep; // A step needs to be selected in order to see insights
      })
      .sort((a, b) => {
        // Sort earliest to latest
        console.log(a, b);
        if (videos != []) {
          // Iterates through the videos to pull the timestamp so the videos are correctly ordered on the graph
          // The timestamp is not stored in the insights table, so it must be fetched
          return videoTimestamp(a.videoId) - videoTimestamp(b.videoId);
        } else {
          return (
            new Date(a.creationDate.toDate()) -
            new Date(b.creationDate.toDate())
          );
        }
      });

    // Update the insight fields
    i.map((j) => {
      j["duration"] = j["duration"] / 1000; // Convert the duration to seconds
      const date = videoTimestamp(j.videoId);
      j["parsedName"] =
        // If option, show filename
        /*(graphAxisDisplay[0] === "filename" ? j.filename + " " : "") +*/
        // If option, create a parsed name for display on the graph
        graphAxisDisplay[1] === "date"
          ? date.getMonth() + 1 + "/" + date.getDate() /* +
           "/" +
            date.getFullYear()*/
          : "";
    });

    return i;
  };

  const filteredInsights = useMemo(
    () => generateFilteredInsights(insights),
    [insights, selectedStep]
  );

  const percentDifference = (a, b) => {
    return 100 * Math.abs((a - b) / ((a + b) / 2));
  };

  // Generate operating times data
  const calculateOperatingTimes = () => {
    const operatingTimes = {};
    insights.forEach((insight) => {
      const videoId = insight.videoId;
      const duration = insight.duration / 1000 / 60; // Convert to minutes
      if (!operatingTimes[videoId]) {
        operatingTimes[videoId] = 0;
      }
      operatingTimes[videoId] += duration; // The operating time of the surgery is the sum of all the identified steps
    });
    return operatingTimes;
  };

  const operatingTimes = calculateOperatingTimes();

  const videoTimestamps = {};
  videos.forEach((video) => {
    videoTimestamps[video.videoId] = video.timestamp.toDate();
  });

  const operatingTimesData = Object.keys(operatingTimes)
    .map((videoId) => ({
      videoId, // Is this even used? No value for this key
      duration: operatingTimes[videoId],
      timestamp: videoTimestamps[videoId],
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const phacometricsData = modelOutputs
    .map((obj) => {
      // console.log(obj.videoId)
      let metrics = JSON.parse(obj.phacometrics.replace(/NaN/g, "null"));
      // console.log(obj.videoId);
      // console.log(metrics);

      function formatTimestampToMonthDay(date) {
        const month = date.getMonth() + 1; // getMonth() is zero-based
        const day = date.getDate();
        return `${month}/${day}`;
      }

      metrics["date"] = formatTimestampToMonthDay(obj.timestamp.toDate());
      metrics["timestamp"] = obj.timestamp.toDate();
      console.log(metrics);
      return metrics;
    })
    .sort((a, b) => a.timestamp - b.timestamp);

  return (
    <>
      <Box>
        <div id={"welcome-dashboard"}>
          <div className="title-wrapper">
            <Heading size="lg">Welcome Back</Heading>
            <Text>
              Here is the information we gathered about your surgeries
            </Text>
          </div>
          {/*<Grid id={"cards-wrapper"} templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem>
              <div className="card-wrapper">
                <div className="title">25</div>
                <span># Surgeries Analyzed</span>
              </div>
            </GridItem>
            <GridItem>
              <div className="card-wrapper">
                <div className="title">17:32</div>
                <span>Avg. Surgery Duration</span>
              </div>
            </GridItem>
            <GridItem>
              <div className="card-wrapper">
                <div className="title">9</div>
                <span>Avg Steps per Surgery </span>
              </div>
            </GridItem>
            //<GridItem>
                            <div className="card-wrapper">
                                <div className="title">89,935</div>
                                <span>Total users</span>
                            </div>
                        </GridItem>
          </Grid>*/}
        </div>
      </Box>

      <Box>
        <Heading size="md" mb={10}>
          Performance Analysis
        </Heading>

        <Box borderRadius={6} shadow="md" bg="white" p={3}>
          <Center px={3}>
            <Box width="100%">
              <Center>
                <Flex>
                  <Heading size="md">Operating Times</Heading>
                </Flex>
              </Center>
              <Flex>
                <Flex minWidth="fit-content" alignItems="center">
                  <Text textAlign="right" transform="rotate(-45deg)">
                    Time
                    <br />
                    (mins)
                  </Text>
                </Flex>
                <Flex minWidth="90%">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={operatingTimesData}
                      onClick={(params) => {
                        // Navigate to video when pressing on node
                        const url = `/video/${uid}/${params.activePayload[0].payload.videoId}`;
                        window.location.href = url;
                      }}
                    >
                      <Line type="monotone" dataKey="duration" stroke="navy" />
                      <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
                      <Tooltip />
                      <XAxis
                        dataKey="timestamp"
                        tickFormatter={(timestamp) => {
                          const date = new Date(timestamp);
                          return `${date.getMonth() + 1}/${date.getDate()}`;
                        }}
                      />
                      <YAxis />
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
              <Center>
                <Text>Surgery Date (oldest to most recent)</Text>
              </Center>
            </Box>
          </Center>

          <Flex m={3} alignItems="center">
            <Flex>
              <Text size="md">
                Select a step to analyze its individual performance:
              </Text>
            </Flex>
            <Select
              m={3}
              width="50%"
              placeholder="Select step"
              onChange={(i) => {
                setSelectedStep(i.target.value);
              }}
            >
              {key.map((i) => {
                return (
                  <option key={i.label} value={i.label}>
                    {i.label}
                  </option>
                );
              })}
            </Select>
          </Flex>

          {/*<Flex p={3}>
            <Center>
              <Text>X-Axis display:</Text>
            </Center>
            <Stack p={2} spacing={3} direction="row">
              <Checkbox
                value={graphAxisDisplay[0]}
                onChange={(i) => {
                  setGraphAxisDisplay([
                    !graphAxisDisplay[0],
                    graphAxisDisplay[1],
                  ]);
                }}
              >
                Filename
              </Checkbox>
              <Checkbox
                value={graphAxisDisplay[1]}
                onChange={(i) => {
                  setGraphAxisDisplay([
                    graphAxisDisplay[0],
                    !graphAxisDisplay[1],
                  ]);
                }}
              >
                Date
              </Checkbox>
            </Stack>
              </Flex>*/}

          <Center px={3}>
            <Box width="100%">
              <Center>
                <Flex>
                  <Heading size="md">
                    {selectedStep}
                    {filteredInsights === null && " - No data yet!"}
                  </Heading>
                </Flex>
              </Center>
              <Flex>
                <Flex minWidth="fit-content" alignItems="center">
                  <Text textAlign="right" transform="rotate(-45deg)">
                    Time
                    <br />
                    (secs)
                  </Text>
                </Flex>
                <Flex minWidth="90%">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={filteredInsights}
                      onClick={(params) => {
                        // Navigate to video when pressing on node
                        const url = `/video/${uid}/${params.activePayload[0].payload.videoId}`;
                        window.location.href = url;
                      }}
                    >
                      <Line
                        connectNulls
                        type="monotone"
                        dataKey="duration"
                        stroke={
                          selectedStep &&
                          key[findLabelIndex(selectedStep)].color
                        }
                      />

                      {/* Improvement line
                                    insights && selectedStep && (
                                        <ReferenceLine
                                            label="improvement"
                                            stroke="green"
                                            strokeDasharray="3 3"
                                            segment={[
                                                {
                                                    x: filteredInsights[0].filename,
                                                    y: filteredInsights[0].duration / 1000,
                                                },
                                                {
                                                    x: filteredInsights[filteredInsights.length - 1]
                                                        .filename,
                                                    y: filteredInsights[filteredInsights.length - 1]
                                                        .duration / 1000,
                                                }
                                            ]}
                                        />
                                    )*/}

                      <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
                      <Tooltip />
                      <XAxis dataKey="parsedName" />

                      <YAxis />
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
              <Center>
                <Text>Surgery Date (oldest to most recent)</Text>
              </Center>
              <Box>
                <Center>
                  {
                    // % performance improvement message
                    filteredInsights && filteredInsights.length > 2 ? (
                      <Box>
                        <Text my={6}>
                          <Stat>
                            You took{" "}
                            <StatArrow
                              type={
                                filteredInsights[filteredInsights.length - 1]
                                  .duration >
                                filteredInsights[filteredInsights.length - 2]
                                  .duration
                                  ? "decrease"
                                  : "increase"
                              }
                            />
                            {Math.abs(
                              percentDifference(
                                filteredInsights[filteredInsights.length - 1]
                                  .duration,
                                filteredInsights[filteredInsights.length - 2]
                                  .duration
                              ).toFixed(3)
                            )}
                            %{" "}
                            {filteredInsights[filteredInsights.length - 1]
                              .duration >
                            filteredInsights[filteredInsights.length - 2]
                              .duration
                              ? "more"
                              : "less"}{" "}
                            time to perform {selectedStep} than your previous
                            surgery in{" "}
                            {
                              filteredInsights[filteredInsights.length - 2]
                                .filename
                            }
                            .
                          </Stat>
                        </Text>

                        <Text my={6}>
                          <Stat>
                            Your time to perform {selectedStep} has{" "}
                            {filteredInsights[filteredInsights.length - 1]
                              .duration > filteredInsights[0].duration
                              ? "increased "
                              : "decreased "}
                            <StatArrow
                              type={
                                filteredInsights[filteredInsights.length - 1]
                                  .duration > filteredInsights[0].duration
                                  ? "decrease"
                                  : "increase"
                              }
                            />
                            {percentDifference(
                              filteredInsights[filteredInsights.length - 1]
                                .duration,
                              filteredInsights[0].duration
                            ).toFixed(3)}
                            % since your first surgery.
                          </Stat>
                        </Text>
                      </Box>
                    ) : null
                  }
                </Center>
              </Box>
            </Box>
          </Center>

          <Center px={3}>
            <Box width="100%">
              <Center>
                <Flex>
                  <Heading size="md">Total Path Length</Heading>
                </Flex>
              </Center>
              <Flex>
                <Flex minWidth="fit-content" alignItems="center">
                  <Text textAlign="right" transform="rotate(-45deg)">
                    Pixels
                  </Text>
                </Flex>
                <Flex minWidth="90%">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={phacometricsData}>
                      <Line
                        type="monotone"
                        name="Blade"
                        dataKey="blade_totalPathLength"
                        stroke="orange"
                      />

                      <Line
                        type="monotone"
                        name="Irrigation-aspiration"
                        dataKey="ia_totalPathLength"
                        stroke="green"
                      />

                      <Line
                        type="monotone"
                        name="Needle Or Cannula"
                        dataKey="needle or cannula_totalPathLength"
                        stroke="brown"
                      />
                      <Line
                        type="monotone"
                        name="Phaco"
                        dataKey="phaco_totalPathLength"
                        stroke="brown"
                      />
                      <Line
                        type="monotone"
                        name="Pupil"
                        dataKey="pupil_totalPathLength"
                        stroke="chocolate"
                      />
                      <Line
                        type="monotone"
                        name="Second Instrument"
                        dataKey="second instrument_totalPathLength"
                        stroke="coral"
                      />

                      <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
                      <Tooltip />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Legend />
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
              <Center>
                <Text>Surgery Date (oldest to most recent)</Text>
              </Center>
            </Box>
          </Center>

          <Center px={3}>
            <Box width="100%">
              <Center>
                <Flex>
                  <Heading size="md">Max Velocity</Heading>
                </Flex>
              </Center>
              <Flex>
                <Flex minWidth="fit-content" alignItems="center">
                  <Text textAlign="right" transform="rotate(-45deg)">
                    Pixels
                    <br />
                    /frame
                  </Text>
                </Flex>
                <Flex minWidth="90%">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={phacometricsData}>
                      <Line
                        type="monotone"
                        name="Blade"
                        dataKey="blade_maxVelocity"
                        stroke="orange"
                      />
                      <Line
                        type="monotone"
                        name="Irrigation-aspiration"
                        dataKey="ia_maxVelocity"
                        stroke="green"
                      />

                      <Line
                        type="monotone"
                        name="Needle Or Cannula"
                        dataKey="needle or cannula_maxVelocity"
                        stroke="brown"
                      />
                      <Line
                        type="monotone"
                        name="Phaco"
                        dataKey="phaco_maxVelocity"
                        stroke="brown"
                      />
                      <Line
                        type="monotone"
                        name="Pupil"
                        dataKey="pupil_maxVelocity"
                        stroke="chocolate"
                      />
                      <Line
                        type="monotone"
                        name="Second Instrument"
                        dataKey="second instrument_maxVelocity"
                        stroke="coral"
                      />
                      <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
                      <Tooltip />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Legend />
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
              <Center>
                <Text>Surgery Date (oldest to most recent)</Text>
              </Center>
            </Box>
          </Center>

          <Center px={3}>
            <Box width="100%">
              <Center>
                <Flex>
                  <Heading size="md">Centration</Heading>
                </Flex>
              </Center>
              <Flex>
                <Flex minWidth="fit-content" alignItems="center">
                  <Text textAlign="right" transform="rotate(-45deg)">
                    Pixels
                  </Text>
                </Flex>
                <Flex minWidth="90%">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={phacometricsData}>
                      <Line
                        type="monotone"
                        name="Phaco"
                        dataKey="phacoCentration"
                        stroke="brown"
                      />
                      {/* <Line
                        type="monotone"
                        name="Zoom Change"
                        dataKey="zoomChange"
                        stroke="chocolate"
                      /> */}

                      <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
                      <Tooltip />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Legend />
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
              <Center>
                <Text>Surgery Date (oldest to most recent)</Text>
              </Center>
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
